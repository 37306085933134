<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="card-title">Sửa hồ sơ</v-card-title>
          <v-card-text class="card-text">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-row>
                    <!-- <v-col cols="12" md="12">
                      <v-img class="v-img" :src="profile.avatar" width="40px"></v-img>
                    </v-col> -->

                    <v-col cols="12" md="6" class="date">
                      <label class="lable" for="start">Số hộ chiếu</label>
                      <input outlined hide-details="auto" v-model="profile.visa_number" label="Số hộ chiếu"
                        class="text-field"/>
                    </v-col>
                    <v-col cols="12" md="6" class="date">
                      <label class="lable" for="start">Tên Tiếng Anh</label>
                      <input outlined hide-details="auto" v-model="profile.name" label="Tên Tiếng Anh"
                        class="text-field"/>
                    </v-col>
                    <v-col cols="12" md="6" class="date" >
                      <label class="lable" for="start">Ngày sinh</label>

                      <input type="date" v-model="profile.date_of_birth" class="" name="trip-start" />
                    </v-col>
                    <v-col cols="12" md="6" class="date" >
                      <!-- <input outlined v-model="profile.balance" label="Ngày đăng kí xin visa" 
                      class="text-field"/> -->
                      <label class="lable" for="start">Ngày đăng kí xin visa</label>

                      <input type="date" v-model="profile.register_date" class="" name="trip-start" />
                    </v-col>
                 
                    <v-col cols="12" md="6" class="date">
                      <label class="lable" for="start">Tư cách lưu trú</label>

                      <input outlined v-model="profile.status_of_residence" label="Tư cách lưu trú"
                        class="text-field"/>
                    </v-col>
                    <v-col cols="12" md="6" class="date">
                      <label class="lable" for="start">Trạng thái tiến trình</label>

                      <input outlined v-model="profile.process_status" label="Trạng thái tiến trình"
                        class="text-field"/>
                    </v-col>
                    <v-col cols="12" md="6" class="date">
                      <label class="lable" for="start">Doanh nghiệp bên Hàn</label>

                      <input outlined v-model="profile.business_in_korea" label="Doanh nghiệp bên Hàn"
                        class="text-field"/>
                    </v-col>
                    <v-col cols="12" md="6" class="date">
                      <label class="lable" for="start">Người được mời</label>

                      <input outlined v-model="profile.invited_person" label="Người được mời"
                        class="text-field"/>
                    </v-col>
                    <v-col cols="12" md="6" class="date"  >
                      <label class="lable" for="start">Ngày xét duyệt</label>

                      <input type="date" class="" v-model="approved_date" name="trip-start" /> </v-col>
                    <v-col cols="12" md="6" class="date">
                      <label class="lable" for="start">Lý do nhập cảnh</label>

                      <input outlined v-model="profile.reason_for_entry" label="Lý do nhập cảnh"
                        class="text-field"/>
                    </v-col>

                    <v-col cols="12" md="12">
                        <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload" />
                        <v-btn @click="openFileInput" outlined color="primary">
                          <v-icon left>mdi-cloud-upload</v-icon>
                          Hình ảnh visa
                        </v-btn>
                        <div style="width: 120%;" class="mt-5">
                          <img :src="profile?.image" v-show="profile.image" alt="Uploaded Image" width="350px" />
                        </div>
                  </v-col>

                    <v-col cols="12">
                      <!-- <v-btn @click="isEditing = true" color="primary" class="button" v-if="!isEditing">Sửa</v-btn> -->
                      <v-btn @click="update" color="primary" class="button">Lưu</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <!-- </div> -->
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col cols="4">
        <v-card>
          <v-card-text class="card-text">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload" />
                  <v-btn @click="openFileInput" outlined color="primary">
                    <v-icon left>mdi-cloud-upload</v-icon>
                    Hình ảnh visa
                  </v-btn>
                  <div style="width: 120%;" class="mt-5">
                    <img :src="bank?.image" v-if="bank.image" alt="Uploaded Image" width="350px" />
                  </div>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>

        </v-card>
        <!-- <v-card class="mt-5">
          <v-card-text class="card-text">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field outlined v-model="amountRecharge" type="number"></v-text-field>
                  <v-btn @click="recharge()" color="primary" class="mt-2">Nạp tiền</v-btn>
                </v-col>
               
              </v-row>
            </v-container>
          </v-card-text>

        </v-card >
        <v-card class="mt-5">
          <v-card-text class="card-text">
            <v-container>
              <v-row>
               
                <v-col cols="12" md="12">
                  <v-text-field outlined v-model="amountWithDraw" type="number"></v-text-field>
                  <v-btn @click="withdraw()" color="primary" class="mt-2">Rút tiền</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

        </v-card> -->
      <!-- </v-col>  -->
    </v-row>
  </v-container>
</template>

<script>
import banks from '@/assets/bank.json'
import network from "@/core/network";
import axios from 'axios';
const types = [
  'CAT RED',
  "CAT SLIVER",
  'CAT BLACK',
  'CAT GOLD'
]
const status = [
  'Chờ xử lý',
  "Đã mua",
  'Dự trữ',
  'Kháng cáo'
]
export default {
  data() {
    return {
      banks: banks.banksnapas,
      new_password: '',
      profile: {
        image: ''
      },
      bank: {
        user_name_bank: '',
        number_bank: '',
        name_bank: ''
      },
      types: types,
      status: status,
      isChangePasse: false,
      isEditing: false, // Biến để kiểm tra trạng thái edit
      amountWithDraw: 0,
      amountRecharge: 0,
    };
  },
  mounted() {
    this.get();
  },

  methods: {
    update() {
      network.post('/userInfo/update', {
        id: this.$route.params.id,
        profile: this.profile
      }).then(res => {
        this.$toast.open({
          message: res.data.message,
          type: 'success',
          position: 'top',
          duration: 3000,
        });   
        this.$router.push('/admin/user')
      }).catch(err => {
        this.$toast.open({
          message: err.response.message,
          type: 'error',
          position: 'top',
          duration: 3000,
        });      
      });
    },
    get() {
      const id = this.$route.params.id;
      network.get(`/userInfo/get/${id}`,).then(res => {
        console.log(res);
        this.profile = res.data;
      })
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];

      if (file) {
        this.file = file;
        // const uriImg = this.convertBase64ToBinary(await this.readFileAsBase64(file));

        const formData = new FormData();
        formData.append('file', file);

        await axios.post(
          network.URI('/upload'),
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                'access_token'
              )}`,
            }
          }
        ).then(res => {
          this.profile.image = res.data.secure_url;
          console.log(this.profile.image);
        }).catch(err => {
          console.log(err);
        })
      }
    },
    async readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async () => {
          const image = new Image();
          image.src = reader.result;

          image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Define the new dimensions for the resized image
            const maxWidth = 200; // Adjust as needed
            const maxHeight = 200; // Adjust as needed
            let width = image.width;
            let height = image.height;

            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            }

            canvas.width = width;
            canvas.height = height;

            // Draw the image onto the canvas
            ctx.drawImage(image, 0, 0, width, height);

            // Convert the canvas content to a data URL
            const resizedDataURL = canvas.toDataURL('image/jpeg');

            // Resolve the promise with the resized data URL
            resolve(resizedDataURL);
          };
        };

        reader.onerror = error => {
          reject(error);
        };
      });
    },
    // getUser() {
    //   const id = this.$route.params.id;
    //   network.get(`/users/${id}`).then(res => {
    //     this.profile = res.data.data.profile;
    //     if (this.profile.bank.user_name_bank) {
    //       this.bank = this.profile.bank;
    //     }
    //   });
    // },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    resetPassword() {
      if (!this.new_password) {
        this.$toast.open({
          message: 'Mật khẩu không được để trống',
          type: 'error',
          position: 'top',
          duration: 3000,
        });
        return false;
      }

      network.post('/users/reset_password', {
        new_password: this.new_password,
        _id: this.$route.params.id
      }).then(res => {
        this.$toast.open({
          message: res.data.message,
          type: 'success',
          position: 'top',
          duration: 3000,
        });
        this.new_password = '';
        this.isChangePasse = false;
      }).catch(err => {
        this.$toast.open({
          message: err.response.data.message,
          type: 'error',
          position: 'top',
          duration: 3000,
        });
      })
    },
    convertBase64ToBinary(dataURI) {
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
      else byteString = unescape(dataURI.split(',')[1]);

      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },
    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
  
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    },

    formatMoney(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
};
</script>

<style>
/* CSS cho đẹp */
.v-img {
  margin-bottom: 10px;
}

.card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.card-text {
  padding: 20px;
}

.text-field {
  margin-bottom: 15px;
}

.button {
  margin-top: 20px;
}

.change-pass-select {
  width: 100%;
  height: 40px;
  border: 1px solid #dddfe2;
  padding: 2px;
  margin-top: 6px;
  border-radius: 4px;
  background: #fff;
  color: #333333;
}

label {
  display: block;
  margin-bottom: 5px;
  /* margin-top: 10px; */
  font:
    1rem 'Fira Sans',
    sans-serif;
}

.date input {
  width: 100%;
  border: 1px solid #b87777;
  height: 58px;
  border-radius: 3px;
  padding: 0px 10px;
}

@media (min-width: 960px) {
  .date label {
    /* margin-top: -30px; */
    margin-bottom: 10px;
  }
}
</style>
